<template>
  <a-select
    :dropdownMatchSelectWidth="false"
    show-search
    dropdownClassName="punyaku"
    placeholder="Select data"
    option-filter-prop="children"
    style="max-width: 100%;"
    v-model="value"
    ref="select"
    :filter-option="filterOption"
    @change="handleChange"
  >
    <a-select-option v-for="(element, index) in options" :key="index" :value="index">
      {{ element[params.reffield] + ' - ' + element.keterangan }}
    </a-select-option>
  </a-select>
</template>

<script>
// import * as lou from '@/services/data/lou'
import Vue from 'vue'
const KEY_BACKSPACE = 8
const KEY_DELETE = 46
const KEY_F2 = 113
const KEY_ENTER = 13
const KEY_TAB = 9
export default Vue.extend({
  name: 'selectInput',
  data() {
    return {
      options: [],
      value: '',
      retvalue: '',
      cancelBeforeStart: true,
      highlightAllOnFocus: true,
      parent: this.$parent.$parent.$parent.$parent.$parent.$parent.$parent,
      defaultValue: '',
    }
  },
  methods: {
    handleChange(index) {
      // console.log('this.options[index][this.params.reffield]', this.options[index][this.params.reffield])
      var value = this.options[index][this.params.reffield]
      // console.log('value', value)
      // if (value === null || value === '') {
      //   lou.shownotif('Data Null', 'Data tidak dapat dipilih karena value null!', 'error')
      //   this.value = this.defaultValue
      //   var data = this.params.data
      //   data[this.params.colDef.field] = this.defaultValue
      //   this.params.node.setData(data)
      // } else {
      if (this.parent.mode !== 'oncreate') {
        this.parent.mode = 'onupdate'
        // console.log('this.parent', this.parent)
        var targetChanged = this.parent.changedData.findIndex(
          (x) => x.id === this.params.data.id,
        )
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.parent.changedData.push(this.params.data)
        } else {
          this.parent.changedData[targetChanged][this.params.column.colId] = this.params.newValue
        }
      }
      // console.log('this.$parent.$parent.changedData', this.$parent.$parent.changedData)
      // console.log('value', value)
      // console.log('this.params.data', this.params)
      // this.value = value
      var tempval = value
      this.retvalue = tempval
      // eslint-disable-next-line no-redeclare
      // console.log('this.params.data', this.params.data)
      var data = this.params.data
      data[this.params.colDef.field] = tempval
      this.params.node.setData(data)
      // }
    },
    getValue() {
      console.log('this.defaultValue', this.defaultValue)
      console.log('this.retvalue', this.retvalue)
      return this.retvalue ?? this.options[0][this.params.reffield]
    },

    isCancelBeforeStart() {
      return this.cancelBeforeStart
    },

    setInitialState(params) {
      let startValue
      let highlightAllOnFocus = true

      if (params.keyPress === KEY_BACKSPACE || params.keyPress === KEY_DELETE) {
        // if backspace or delete pressed, we clear the cell
        startValue = ''
      } else if (params.charPress) {
        // if a letter was pressed, we start with the letter
        startValue = params.charPress
        highlightAllOnFocus = false
      } else {
        // otherwise we start with the current value
        startValue = params.value
        if (params.keyPress === KEY_F2) {
          highlightAllOnFocus = false
        }
      }

      console.log('this.params', this.params)
      var indx = this.params.values.findIndex(x => x[this.params.reffield] === startValue)
      // console.log('indx', indx)
      this.value = indx !== -1 ? indx : this.params.values[0][this.params.reffield]
      this.retvalue = startValue
      this.defaultValue = startValue
      this.highlightAllOnFocus = highlightAllOnFocus
    },

    // will reject the number if it greater than 1,000,000
    // not very practical, but demonstrates the method.
    isCancelAfterEnd() {
      return this.value > 100000000
    },

    onKeyDown(event) {
      if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
        event.stopPropagation()
        return
      }

      if (
        !this.finishedEditingPressed(event)
      ) {
        if (event.preventDefault) event.preventDefault()
      }
    },

    finishedEditingPressed(event) {
      const charCode = this.getCharCodeFromEvent(event)
      return charCode === KEY_ENTER || charCode === KEY_TAB
    },

    deleteOrBackspace(event) {
      return (
        [KEY_DELETE, KEY_BACKSPACE].indexOf(this.getCharCodeFromEvent(event)) >
        -1
      )
    },

    isLeftOrRight(event) {
      return [37, 39].indexOf(this.getCharCodeFromEvent(event)) > -1
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
  },

  created() {
    // console.log('this.params', this.params)
    this.setInitialState(this.params)

    // only start edit if key pressed is a number, not a letter
    this.cancelBeforeStart =
      this.params.charPress && '1234567890'.indexOf(this.params.charPress) < 0
  },
  mounted() {
    this.options = this.params.values.map((x) => {
      x[this.params.reffield] = x[this.params.reffield] || ''
      x.keterangan = x.keterangan || ''
      return x
    })
    // this.$nextTick(() => {
    //   // need to check if the input reference is still valid - if the edit was cancelled before it started there
    //   // wont be an editor component anymore
    //   if (this.$refs.select) {
    //     this.$refs.select.focus()
    //     if (this.highlightAllOnFocus) {
    //       // this.$refs.select.select()

    //       this.highlightAllOnFocus = false
    //     } else {
    //       // when we started editing, we want the carot at the end, not the start.
    //       // this comes into play in two scenarios: a) when user hits F2 and b)
    //       // when user hits a printable character, then on IE (and only IE) the carot
    //       // was placed after the first character, thus 'apply' would end up as 'pplea'
    //       const length = this.$refs.select.value
    //         ? this.$refs.select.value.length
    //         : 0
    //       if (length > 0) {
    //         this.$refs.select.setSelectionRange(length, length)
    //       }
    //     }

    //     this.$refs.select.focus()
    //   }
    // })
  },
})
</script>

<style>
.punyaku {
  max-width: 350px !important;
  min-width: 100px !important;
  white-space: initial !important;
}

</style>
