<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>{{ title }}</strong>

        <a-button
          type="reset"
          class="btn btn-sm btn-outline-danger mb-0 ml-3"
          @click="back()"
        >
          Back
        </a-button>
        <a-button class="btn btn-outline-success ml-3" @click="handleSubmit"
          >Save Changes</a-button
        >
      </template>
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="Nama">
          <div class="row">
            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
              <div class="row">
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <label>No. Register</label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                  <!-- :readOnly="action === 'update'" -->
                  <a-input
                    v-if="action === 'update'"
                    style="width: 150px"
                    :readOnly="action === 'update'"
                    v-model="editdata.kode"
                    :max-length="3"
                  ></a-input>
                  <a-input
                    v-else
                    style="width: 70px"
                    v-model="formData.cabangentry"
                    :max-length="3"
                  ></a-input>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <label>Tanggal Register</label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
                  <a-date-picker
                    v-if="action === 'create'"
                    style="width: 120px"
                    format="DD-MM-YYYY"
                    v-model="formData.tglregister"
                    placeholder="Tanggal Register"
                    :allowClear="false"
                  />
                  <input
                    v-else
                    :readOnly="true"
                    class="ant-input"
                    v-model="formData.tglregister"
                    placeholder="Tanggal Register"
                  />
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <label>Nama Lengkap</label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
                  <a-input v-model="formData.nama"></a-input>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <label>Sandi Bank</label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
                  <a-input
                    v-model="formData.sandibank"
                    style="width: 40%"
                    :max-length="6"
                  ></a-input>
                  <!-- <v-select
                    :clearable="false"
                    class="style-chooser"
                    v-model="formData.sandibank"
                    placeholder="Sandi Bank"
                    label="keterangan"
                    :options="[]"
                    :reduce="(tes) => tes.kode"
                      /> -->
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <label>Jenis Badan Usaha</label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
                  <a-input
                    v-model="formData.jenisbadanusaha"
                    style="width: 40%"
                  ></a-input>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <label>Tgl Akte Pendirian</label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
                  <a-date-picker
                    :key="componentKey"
                    style="width: 120px"
                    format="DD-MM-YYYY"
                    v-model="formData.tglktp"
                    placeholder="Akte Pendirian"
                    :allowClear="false"
                    @change="dateRange"
                  />
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <label>No Akte Pendirian</label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
                  <a-input v-model="formData.ktp" style="width: 80%"></a-input>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <label>Tempat Akte</label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
                  <a-input
                    v-model="formData.tempatlahir"
                    style="width: 80%"
                  ></a-input>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <label>Pekerjaan</label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
                  <v-select
                    :clearable="false"
                    class="style-chooser-pos-relative"
                    v-model="formData.pekerjaan"
                    placeholder="Pekerjaan"
                    label="keterangan"
                    :options="dataPekerjaan"
                    :reduce="(tes) => tes.kode"
                    style="width: 80%"
                  />
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <label>Instansi</label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
                  <v-select
                    :clearable="false"
                    class="style-chooser-pos-relative"
                    v-model="formData.instansi"
                    placeholder="Instansi"
                    label="keterangan"
                    :options="dataInstansi"
                    :reduce="(tes) => tes.kode"
                    style="width: 80%"
                  />
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <label>NIP</label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
                  <a-input
                    v-model="formData.nip"
                    style="width: 80%"
                    :max-length="18"
                  ></a-input>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <label>NPWP</label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
                  <a-input
                    v-model="formData.npwp"
                    style="width: 80%"
                    :max-length="15"
                  ></a-input>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="Data Akte Perusahaan">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <label>Alamat</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <a-input
                    v-model="formData.alamat"
                    style="width: 100%"
                  ></a-input>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label>Telp. Rumah</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    v-model="formData.telepon"
                    style="width: 100%"
                  ></a-input>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label>Nomor HP</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input v-model="formData.hp" style="width: 100%"></a-input>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label>Faximile</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input v-model="formData.fax" style="width: 100%"></a-input>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label>Kode Pos</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    v-model="formData.kodepos"
                    style="width: 100%"
                  ></a-input>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label>Kode/Kab</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <v-select
                    :clearable="false"
                    class="style-chooser-pos-relative"
                    placeholder="Kodya"
                    style="width: 80%"
                    label="keterangan"
                    v-model="formData.kodya"
                    :options="dataKodya"
                    :reduce="(tes) => tes.kode"
                    @input="(e) => getKecamatan(e, '')"
                  />
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label>Kecamatan</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <v-select
                    :clearable="false"
                    class="style-chooser-pos-relative"
                    label="keterangan"
                    v-model="formData.kecamatan"
                    placeholder="Kecamatan"
                    :options="dataKecamatan"
                    :reduce="(tes) => tes.kode"
                    @input="(e) => getKelurahan('kodya', e, '')"
                    style="width: 80%"
                  />
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label>Kelurahan</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <v-select
                    :clearable="false"
                    class="style-chooser-pos-relative"
                    v-model="formData.kelurahan"
                    placeholder="Kelurahan"
                    label="keterangan"
                    :options="dataKelurahan"
                    :reduce="(tes) => tes.kode"
                    style="width: 80%"
                  />
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label>RT/RW</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    v-model="formData.rtrw"
                    style="width: 100%"
                  ></a-input>
                </div>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <strong>Fota Rumah</strong>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <center>
                    <a-card style="max-width: 450px; max-height: 450px">
                      <img
                        src="https://dummyimage.com/450x300/000/fff.png&text=Foto+Sementara"
                        alt="Testing"
                      />
                    </a-card>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" tab="Pengurus">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <!-- v-if="mode === 'netral'" -->
              <a-button
                ref="addBtn"
                class="btn btn-outline-primary"
                @click="addNullData"
                >
                Tambah
              </a-button>
                <a-button
                  v-if="selectedData !== null"
                  ref="addBtn"
                  class="btn btn-outline-danger ml-3"
                  @click="removeFromCart(selectedIndex)"
                >
                  Remove
                </a-button>
              <!-- :disabled="mode !== 'netral' && mode !== 'oncreate'" -->
              <!-- v-if="mode === 'netral'" -->
              <!-- :disabled="mode !== 'netral' && mode !== 'oncreate'" -->
              <!-- v-if="selectedData === null" -->
              <!-- <a-button
              class="btn btn-outline-success ml-2"
              @click="saveChanges"
              v-if="mode === 'onupdate' || mode === 'oncreate'"
            >
              Save Changes
            </a-button>
            <a-button
              class="btn btn-outline-danger ml-2 mr-2"
              @click="cancelaction"
              v-if="mode === 'onupdate' || mode === 'oncreate'"
            >
              Cancel
            </a-button> -->
              <!-- <div> -->
              <!-- <a-button
                ref="addBtn"
                class="btn btn-outline-primary"
                @click="controlModal"
              >
                Tambah Data Pengurus
              </a-button>
                <a-button
                  v-if="selectedData !== null"
                  ref="addBtn"
                  class="btn btn-outline-warning ml-3"
                  @click="controlModal('update', selectedData)"
                >
                  Edit
                </a-button>
                <a-button
                  v-if="formData.pengurus.length > 1"
                  ref="addBtn"
                  class="btn btn-outline-secondary ml-3"
                  @click="batalPilihFunc"
                >
                  Batal pilih
                </a-button> -->
              <!-- </div> -->
              <!-- <a-button
              class="btn btn-outline-success ml-2"
              @click="saveChanges"
              v-if="mode === 'onupdate' || mode === 'oncreate'"
            >
              Save Changes
            </a-button>
            <a-button
              class="btn btn-outline-danger ml-2 mr-2"
              @click="cancelaction"
              v-if="mode === 'onupdate' || mode === 'oncreate'"
            >
              Cancel
            </a-button> -->
              <ag-grid-vue
                :style="
                  settings.isMobileView ? 'height: 400px;' : 'height: 50vh;'
                "
                :cacheQuickFilter="true"
                :singleClickEdit="false"
                :frameworkComponents="frameworkComponents"
                :gridOptions="gridOptions"
                :rowSelection="rowSelection"
                @grid-ready="onGridReady"
                @first-data-rendered="onFirstDataRendered"
                @selection-changed="onSelectionChanged"
                :rowClassRules="rowClassRules"
                :class="
                  settings.theme === 'dark'
                    ? 'ag-theme-balham-dark mt-2'
                    : 'ag-theme-balham mt-2'
                "
                :columnDefs="columnDefs"
                :rowData="formData.pengurus"
              ></ag-grid-vue>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <label></label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9"></div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label></label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1"></div>
              </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-card>
    <a-modal
      v-model="modalform.visible"
      title="Form Data Pengurus"
      :width="500"
      :dialogStyle="{ top: '10px' }"
    >
      <template slot="footer">
        <a-button class="btn btn-outline-danger" @click="closeModal"
          >Cancel</a-button
        >
        <a-button class="btn btn-outline-primary" @click="saveToCart"
          >Submit</a-button
        >
      </template>
      <div class="row">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <label>No. Identitas</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
          <a-input
            v-model="modalform.nomor_Identitas"
            :max-length="16"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label>Jenis Identitas</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <v-select
            :clearable="false"
            class="style-chooser-pos-relative"
            v-model="modalform.jenis_Identitas"
            placeholder="Jenis Identitas"
            label="keterangan"
            :options="dataJenisIdentitas"
            :reduce="(tes) => tes.kode"
            style="width: 80%"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label>Nama</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            v-model="modalform.nama_Pengurus_Pemilik"
            style="width: 60%"
          ></a-input>
          (Tanpa Gelar)
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label>Jenis Kelamin</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-radio-group v-model="modalform.jenis_Kelamin">
            <a-radio-button value="L"> Laki-laki </a-radio-button>
            <a-radio-button value="P"> Perempuan </a-radio-button>
          </a-radio-group>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label>Alamat</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input v-model="modalform.alamat"></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label>Kelurahan</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input v-model="modalform.kelurahan"></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label>Kecamatan</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input v-model="modalform.kecamatan"></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label>Dati II</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <v-select
            :clearable="false"
            class="style-chooser-pos-relative"
            v-model="modalform.dati2"
            placeholder="Dati II"
            label="keterangan"
            :options="dataKodya"
            :reduce="(tes) => tes.kode"
            style="width: 80%"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label>Kode Jabatan</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <v-select
            :clearable="false"
            class="style-chooser-pos-relative"
            v-model="modalform.kode_Jabatan"
            placeholder="Kode Jabatan"
            label="keterangan"
            :options="datakodejabatan"
            :reduce="(tes) => tes.kode"
            style="width: 80%"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label>Kepemilikan</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            v-model="modalform.pangsa_Kepemilikan"
            style="width: 100px"
          ></a-input>
          %
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label>Status</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <v-select
            :clearable="false"
            class="style-chooser-pos-relative"
            v-model="modalform.status_Pengurus_Pemilik"
            placeholder="Status"
            label="keterangan"
            :options="datastatuskepemilikan"
            :reduce="(tes) => tes.kode"
            style="width: 80%"
          />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import moment from 'moment'
import * as lou from '@/services/data/lou'
import numericInput from './component/numeric'
import selectInput from './component/select'
import dateInput from './component/date'
import tooltip from './component/tooltip'
import htmlrender from './component/htmlrender'
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  mounted() {
    this.gridOptions = {}
    // this.gridApi = this.gridOptions.api
    // this.gridColumnApi = this.gridOptions.columnApi
    this.getAllMaster()
    // this.getMaster()
    if (this.editdata.id !== '') {
      this.action = 'update'
      this.title = 'Update Badan Usaha'
      this.getEditData()
    } else {
      this.action = 'create'
      this.title = 'Create Badan Usaha'
    }
  },
  data() {
    return {
      rowSelection: 'single',
      modalform: {
        action: 'create',
        editedindex: 0,
        visible: false,
        cif: '',
        nomor_Identitas: '',
        jenis_Identitas: '',
        nama_Pengurus_Pemilik: '',
        jenis_Kelamin: '',
        alamat: '',
        kelurahan: '',
        kecamatan: '',
        dati2: '',
        kode_Jabatan: '',
        pangsa_Kepemilikan: '',
        status_Pengurus_Pemilik: '',
      },

      mode: 'netral',
      theme: this.$store.state.settings.theme,
      gridOptions: null,
      gridApi: null,
      frameworkComponents: {
        numericEditor: numericInput,
        dateEditor: dateInput,
        selectEditor: selectInput,
        customTooltip: tooltip,
        htmlRender: htmlrender,
      },
      rowClassRules: {
        'text-success': (params) => {
          return params.data.newdata !== undefined
        },
        'text-warning': (params) => {
          return params.data.edited !== undefined
        },
        'text-danger': (params) => {
          return params.data.isError !== undefined
        },
        'text-primary': (params) => {
          return (
            params.data.isPrimary !== undefined && params.data.isPrimary === 1
          )
        },
        'font-weight-bold': (params) => {
          return params.data.isBold !== undefined
        },
      },
      selectedData: null,
      selectedIndex: 0,
      selectedRowNode: null,
      backupRowData: [],
      rowData: null,
      changedData: [],

      columnDefs: [],
      datadefs: [],

      inputOptions: {
        jumlahnontunai: {
          readOnly: false,
        },
        jumlahtunai: {
          readOnly: false,
        },
      },

      dateFormat: ['DD/MM/YYYY', 'DD/MM/YY'],
      form: this.$form.createForm(this),
      editdata: JSON.parse(
        localStorage.getItem('cbsjombangeditregisterbadanusahadata'),
      ),
      alamatktpdantempattinggalsama: true,
      config: {
        rules: [
          { type: 'object', required: false, message: 'Tolong pilih tanggal!' },
        ],
      },
      errorMsg: null,
      successMsg: null,
      action: null,
      title: 'Register Badan Usaha',
      showHistory: true,
      showInformation: false,

      datajenisidentitas: [],
      datakodejabatan: [],
      datastatuskepemilikan: [],
      dataKodya: [],
      dataAgama: [],
      dataPekerjaan: [],
      dataGelar: [],
      dataInstansi: [],
      dataJenisIdentitas: [],
      dataKecamatan: [],
      dataKelurahan: [],
      dataStatus: [
        {
          kode: '1',
          keterangan: '1 - Aktif',
        },
        {
          kode: '2',
          keterangan: '2 - Tidak Aktif',
        },
      ],

      formData: {
        nama: '',
        sandibank: '',
        jenisbadanusaha: '',
        tgllahir: moment(),
        ktp: '',
        tempatlahir: '',
        pekerjaan: '',
        instansi: '',
        nip: '',
        npwp: '',
        alamat: '',
        telepon: '',
        hp: '',
        fax: '',
        kodepos: '',
        kodya: '',
        kecamatan: '',
        kelurahan: '',
        rtrw: '',
        cabangentry: '',
        tglregister: moment(),
        kode: '',
        pengurus: [
          // {
          //   id: '0',
          //   Nomor_Identitas: '11535454',
          //   Jenis_Identitas: '1',
          //   Nama_Pengurus_Pemilik: 'Lou',
          //   Jenis_Kelamin: 'L',
          //   Alamat: 'Ttesting',
          //   Kelurahan: 'TKJST',
          //   Kecamatan: 'NVXCXV',
          //   dati2: '0114',
          //   Kode_Jabatan: '01',
          //   Pangsa_Kepemilikan: '15',
          //   Status_Pengurus_Pemilik: '1',
          // },
          // {
          //   id: '1',
          //   Nomor_Identitas: '45488777',
          //   Jenis_Identitas: '1',
          //   Nama_Pengurus_Pemilik: 'Lou',
          //   Jenis_Kelamin: 'L',
          //   Alamat: 'Ttesting',
          //   Kelurahan: 'TKJST',
          //   Kecamatan: 'NVXCXV',
          //   dati2: '0114',
          //   Kode_Jabatan: '01',
          //   Pangsa_Kepemilikan: '15',
          //   Status_Pengurus_Pemilik: '1',
          // },
        ],
      },
      componentKey: 0,
    }
  },
  methods: {
    addColumnDefs() {
      // console.log('this.datajenisidentitas', this.datajenisidentitas)
      // console.log('this.datastatuskepemilikan', this.datastatuskepemilikan)
      var tempColumnDefs = [
        {
          headerName: 'No Identitas',
          field: 'nomor_Identitas',
          resizable: true,
          filter: true,
          editable: true,
        },
        {
          headerName: 'Jenis Identitas',
          field: 'jenis_Identitas',
          resizable: true,
          filter: true,
          editable: true,
        },
        {
          headerName: 'Nama Pengurus',
          field: 'nama_Pengurus_Pemilik',
          resizable: true,
          filter: true,
          editable: true,
        },
        {
          headerName: 'Jenis Kelamin',
          field: 'jenis_Kelamin',
          resizable: true,
          filter: true,
          editable: true,
          cellEditor: 'selectEditor',
          cellEditorParams: {
            values: [
              {
                kode: 'L',
                keterangan: 'Laki-laki',
              },
              {
                kode: 'P',
                keterangan: 'Perempuan',
              },
            ],
            reffield: 'kode',
          },
        },
        {
          headerName: 'Alamat',
          field: 'alamat',
          resizable: true,
          filter: true,
          editable: true,
        },
        {
          headerName: 'Kelurahan',
          field: 'kelurahan',
          resizable: true,
          filter: true,
          editable: true,
          cellEditor: 'selectEditor',
          cellEditorParams: {
            values: this.dataKelurahan,
            reffield: 'kode',
          },
        },
        {
          headerName: 'Kecamatan',
          field: 'kecamatan',
          resizable: true,
          filter: true,
          editable: true,
          cellEditor: 'selectEditor',
          cellEditorParams: {
            values: this.dataKecamatan,
            reffield: 'kode',
          },
        },
        {
          headerName: 'Dati II',
          field: 'dati2',
          resizable: true,
          filter: true,
          editable: true,
          cellEditor: 'selectEditor',
          cellEditorParams: {
            values: this.dataKodya,
            reffield: 'kode',
          },
        },
        {
          headerName: 'Kode Jabatan',
          field: 'kode_Jabatan',
          resizable: true,
          filter: true,
          editable: true,
          cellEditor: 'selectEditor',
          cellEditorParams: {
            values: this.datakodejabatan,
            reffield: 'kode',
          },
        },
        {
          headerName: 'Pangsa Kepemilikan',
          field: 'pangsa_Kepemilikan',
          resizable: true,
          filter: true,
          editable: true,
        },
        {
          headerName: 'Status',
          field: 'status_Pengurus_Pemilik',
          resizable: true,
          filter: true,
          editable: true,
          cellEditor: 'selectEditor',
          cellEditorParams: {
            values: this.datastatuskepemilikan,
            reffield: 'kode',
          },
        },
      ]

      if (this.dataJenisIdentitas.length !== 0 && this.dataJenisIdentitas !== null) {
        tempColumnDefs[0] = {
          headerName: 'Jenis Identitas',
          field: 'jenis_Identitas',
          resizable: true,
          filter: true,
          editable: true,
          cellEditor: 'selectEditor',
          cellEditorParams: {
            values: this.dataJenisIdentitas,
            reffield: 'kode',
          },
        }
      }

      if (this.datastatuskepemilikan.length !== 0 && this.datastatuskepemilikan !== null) {
        tempColumnDefs[10] = {
          headerName: 'Status',
          field: 'status_Pengurus_Pemilik',
          resizable: true,
          filter: true,
          editable: true,
          cellEditor: 'selectEditor',
          cellEditorParams: {
            values: this.datastatuskepemilikan,
            reffield: 'kode',
          },
        }
      }

      this.columnDefs = this.$g.clone(tempColumnDefs)
    },
    closeModal() {
      this.modalform.visible = false
      // this.resetModalForm()
    },
    dateRange(v, v2) {
      console.log('v', v)
      console.log('v2', v2)
      console.log('this.formData.tglktp', this.formData.tglktp)
      this.componentKey += 1
    },
    callback(key) {
      console.log(key)
    },
    moment,
    batalPilihFunc() {
      this.selectedData = null
      this.selectedIndex = 0
      this.resetModalForm()
    },
    onSelectionChanged() {
      console.log('this.gridApi', this.gridApi)
      var selectedRows = this.gridApi.getSelectedRows()
      console.log('this.selectedRows', this.selectedRows)
      var getSelectedNodes = this.gridApi.getSelectedNodes()
      console.log('')
      this.selectedData = selectedRows[0]
      console.log('this.selectedData', this.selectedData)
      this.selectedIndex = getSelectedNodes[0].rowIndex
      this.selectedRowNode = this.gridApi.getRowNode(this.selectedData.id)
      console.log('this.selectedRowNode', this.selectedRowNode)
      // this.index = selectedRows[0]
      // console.log('selectedRows', selectedRows)
    },
    removeFromCart(index) {
      this.formData.pengurus.splice(index, 1)
      this.resetModalForm()
    },
    saveToCart() {
      var fd = this.$g.clone(this.modalform)
      // console.log('fd', fd)
      if (this.modalform.action === 'update') {
        // console.log('this.selectedData.id', this.selectedData.id)
        // var rowNode = this.gridApi.getRowNode(this.selectedData.id.toString())
        // console.log('rowNode', rowNode)
        // rowNode.setData(fd)
        var tempdata = this.$g.clone(this.formData.pengurus)
        var tindex = tempdata.findIndex((x) => x.id === fd.id)
        console.log('tindex', tindex)
        if (tindex === -1) {
          lou.shownotif(
            'error!',
            'Data not found',
            'Data yang bersangkutan telah hilang atau dihapus',
          )
        } else {
          console.log('this.selectedRowNode', this.selectedRowNode)
          this.selectedRowNode.setData(fd)
          // tempdata[tindex] = fd
          // this.formData.pengurus = this.$g.clone(tempdata)
        }
      } else {
        fd.id = this.$uuid.v4()
        fd.newdata = true
        this.formData.pengurus.push(fd)
      }
      this.modalform.visible = false
      // this.resetModalForm()
      this.gridApi.redrawRows()
      setTimeout(() => {
        this.refreshTable()
      }, 500)
    },
    controlModal(action = 'create', data = {}) {
      // this.getMaster()
      this.modalform.action = action
      this.modalform.visible = !this.modalform.visible
      if (action === 'update') {
        this.modalform.cif = data.cif
        this.modalform.nomor_Identitas = data.nomor_Identitas
        this.modalform.jenis_Identitas = data.jenis_Identitas
        this.modalform.nama_Pengurus_Pemilik = data.nama_Pengurus_Pemilik
        this.modalform.jenis_Kelamin = data.jenis_Kelamin
        this.modalform.alamat = data.alamat
        this.modalform.kelurahan = data.kelurahan
        this.modalform.kecamatan = data.kecamatan
        this.modalform.dati2 = data.dati2
        this.modalform.kode_Jabatan = data.kode_Jabatan
        this.modalform.pangsa_Kepemilikan = data.pangsa_Kepemilikan
        this.modalform.status_Pengurus_Pemilik = data.status_Pengurus_Pemilik
      } else {
        this.resetModalForm()
      }
    },
    resetModalForm() {
      this.selectedData = null
      this.selectedIndex = 0
      this.modalform.cif = ''
      this.modalform.nomor_Identitas = ''
      this.modalform.jenis_Identitas = ''
      this.modalform.nama_Pengurus_Pemilik = ''
      this.modalform.jenis_Kelamin = ''
      this.modalform.alamat = ''
      this.modalform.kelurahan = ''
      this.modalform.kecamatan = ''
      this.modalform.dati2 = ''
      this.modalform.kode_Jabatan = ''
      this.modalform.pangsa_Kepemilikan = ''
      this.modalform.status_Pengurus_Pemilik = ''
    },
    async getEditData() {
      // console.log('this.editdata', this.editdata)
      // this.formData = this.$g.clone(this.editdata)
      this.formData.kode = this.editdata.kode
      var res = await lou.customUrlGet(
        'RegisterBadanUsaha/' + this.formData.kode,
      )
      // console.log('res', res.data)
      this.formData.nama = res.data.nama
      this.formData.sandibank = res.data.sandibank
      this.formData.jenisbadanusaha = res.data.jenisbadanusaha
      this.formData.ktp = res.data.ktp
      this.formData.tempatlahir = res.data.tempatlahir
      this.formData.pekerjaan = res.data.pekerjaan
      this.formData.instansi = res.data.instansi
      this.formData.nip = res.data.nip
      this.formData.npwp = res.data.npwp
      this.formData.alamat = res.data.alamat
      this.formData.telepon = res.data.telepon
      this.formData.hp = res.data.hp
      this.formData.fax = res.data.fax
      this.formData.kodepos = res.data.kodepos
      this.formData.kodya = res.data.kodya

      if (
        this.formData.kodya !== null &&
        this.formData.kodya !== '' &&
        this.formData.kodya !== undefined
      ) {
        this.getKecamatan(this.formData.kodya, '')
      }
      this.formData.kecamatan = res.data.kecamatan
      if (
        this.formData.kecamatan !== null &&
        this.formData.kecamatan !== '' &&
        this.formData.kecamatan !== undefined
      ) {
        this.getKelurahan('kodya', this.formData.kecamatan, '')
      }

      this.formData.kelurahan = res.data.kelurahan
      this.formData.rtrw = res.data.rtrw
      this.formData.cabangentry = res.data.cabangentry
      this.formData.tglregister = moment(
        res.data.tglregister,
        'YYYY-MM-DD',
      ).format('YYYY-MM-DD')
      // this.formData.tglregister = res.data.tglregister
      this.formData.tglktp = moment(res.data.tglktp, 'YYYY-MM-DD')
      // this.formData.tglktp = res.data.tglktp
      if (res.data.pengurus !== undefined && res.data.pengurus !== null) {
        var datap = this.$g.clone(res.data.pengurus)
        datap.forEach((element) => {
          if (element.id === undefined) {
            element.id = this.$uuid.v4()
          }
          element.newdata = false
        })
        this.formData.pengurus = res.data.pengurus
        this.backupRowData = this.$g.clone(res.data.pengurus)
      }
      // console.log('this.editdata', this.editdata)
    },
    async getMaster() {
      // var jenisidentitas = await lou.customUrlGet(
      //   'RegisterBadanUsaha/jenis_identitas',
      //   false,
      //   false,
      //   false,
      //   false,
      // )
    },
    async getAllMaster() {
      var dataagama = await lou.customUrlGet(
        'RegisterBadanUsaha/Agama',
        false,
        false,
        false,
        false,
      )
      var datakodya = await lou.customUrlGet(
        'RegisterBadanUsaha/kodya',
        false,
        false,
        false,
        false,
      )
      // var datakecamatan = await lou.customUrlGet('RegisterBadanUsaha/Kecamatan', false, false, false, false)
      // var datakelurahan = await lou.customUrlGet('RegisterBadanUsaha/Kelurahan', false, false, false, false)
      var datapekerjaan = await lou.customUrlGet(
        'RegisterBadanUsaha/Pekerjaan',
        false,
        false,
        false,
        false,
      )
      var datagelar = await lou.customUrlGet(
        'RegisterBadanUsaha/Gelar',
        false,
        false,
        false,
        false,
      )
      var dataInstansi = await lou.customUrlGet(
        'RegisterBadanUsaha/Instansi',
        false,
        false,
        false,
        false,
      )
      var dataJenisIdentitas = await lou.customUrlGet(
        'RegisterBadanUsaha/Jenis_Identitas',
        false,
        false,
        false,
        false,
      )
      var kodejabatan = await lou.customUrlGet(
        'RegisterBadanUsaha/kode_jabatan_pengurus',
        false,
        false,
        false,
        false,
      )
      var statuskepemilikan = await lou.customUrlGet(
        'RegisterBadanUsaha/status_pengurus',
        false,
        false,
        false,
        false,
      )
      // if (jenisidentitas) {
      //   this.datajenisidentitas = jenisidentitas.data
      //   this.allMaster.jenisidentitas = jenisidentitas.data
      // }

      this.allMaster = {}

      if (datakodya) {
        this.dataKodya = datakodya.data
        this.allMaster.kodya = datakodya.data
      }

      if (dataagama) {
        this.dataAgama = dataagama.data
        this.allMaster.agama = dataagama.data
      }

      if (datapekerjaan) {
        this.dataPekerjaan = datapekerjaan.data
        this.allMaster.pekerjaan = datapekerjaan.data
      }

      if (datagelar) {
        this.dataGelar = datagelar.data
        this.allMaster.gelar = datagelar.data
      }

      if (dataInstansi) {
        this.dataInstansi = dataInstansi.data
        this.allMaster.instansi = dataInstansi.data
      }

      if (dataJenisIdentitas) {
        this.dataJenisIdentitas = dataJenisIdentitas.data
        this.allMaster.instansi = dataJenisIdentitas.data
      }
      if (kodejabatan) {
        this.datakodejabatan = kodejabatan.data
        this.allMaster.kodejabatan = kodejabatan.data
      }
      if (statuskepemilikan) {
        this.datastatuskepemilikan = statuskepemilikan.data
        this.allMaster.statuskepemilikan = statuskepemilikan.data
      }
      // setTimeout(() => {
      this.addColumnDefs()
      // }, 1000)
    },
    async getKecamatan(kodya, from) {
      this.formData.kecamatan = ''
      var datakecamatan = await lou.customUrlGet(
        'RegisterBadanUsaha/Kecamatan?kodya=' + kodya,
      )
      // this.dataKecamatan = datakecamatan.data
      this['dataKecamatan' + from] = datakecamatan.data
      // console.log("this['dataKecamatan' + from]", this['dataKecamatan' + from])
    },
    async getKelurahan(kody, kecamatan, from) {
      this.formData.kelurahan = ''
      var kodya = this.formData[kody]
      var datakelurahan = await lou.customUrlGet(
        'RegisterBadanUsaha/Kelurahan?kodya=' +
          kodya +
          '&kecamatan=' +
          kecamatan,
      )
      // this.dataKelurahan = datakelurahan.data
      this['dataKelurahan' + from] = datakelurahan.data
    },
    handleSubmit(e) {
      Modal.confirm({
        title: 'Are you sure the data is correct?',
        content: 'You can edit the data later! ',
        okText: 'Yes',
        okType: 'primary',
        cancelText: 'No',
        onOk: async () => {
          // Should format date value before submit.
          var res = false
          var fd = this.formData
          try {
            fd.tglktp =
              this.formData.tglktp !== null
                ? this.formData.tglktp.format('YYYY-MM-DD')
                : null
          } catch (error) {
            fd.tglktp =
              this.formData.tglktp !== null ? this.formData.tglktp : null
          }
          try {
            fd.tgllahir =
              this.formData.tgllahir !== null
                ? this.formData.tgllahir.format('YYYY-MM-DD')
                : null
          } catch (error) {
            fd.tgllahir =
              this.formData.tgllahir !== null ? this.formData.tgllahir : null
          }
          try {
            fd.tglregister =
              this.formData.tglregister !== null
                ? this.formData.tglregister.format('YYYY-MM-DD')
                : null
          } catch (error) {
            fd.tglregister =
              this.formData.tglregister !== null
                ? this.formData.tglregister
                : null
          }
          fd.pengurus.forEach((element) => {
            if (element.newdata) {
              delete element.id
            }
            delete element.newdata
          })
          if (this.action === 'update') {
            res = await lou.customUrlPut('RegisterBadanUsaha', fd)
          } else {
            delete fd.id
            res = await lou.customUrlPost('RegisterBadanUsaha', fd)
          }
          if (res) {
            Modal.confirm({
              title: 'Kembali?',
              content: 'Kembali ke halaman data?! ',
              okText: 'Yes',
              okType: 'primary',
              cancelText: 'No',
              onOk: () => {
                // Should format date value before submit.
                this.$router.push('/cbsjombang/registerbadanusaha/data')
              },
              onCancel: () => {
                if (this.action !== 'update') {
                  Modal.confirm({
                    title: 'Reset Form?',
                    content: 'Data Input Akan direset?! ',
                    okText: 'Yes',
                    okType: 'primary',
                    cancelText: 'No',
                    onOk: () => {
                      this.resetForm()
                    },
                    onCancel: () => {
                      fd.tglktp =
                        this.formData.tglktp !== null
                          ? moment(this.formData.tglktp, 'YYYY-MM-DD')
                          : moment()
                      // console.log('Cancel')
                    },
                  })
                }
              },
            })
          }
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    addNullData() {
      this.mode = 'oncreate'
      var fd = {}
      this.columnDefs.forEach((element, index) => {
        var booltgl = element.cellEditor === 'dateEditor'
        if (
          element.field === 'flagdetail' ||
          element.field === 'FlagDetail' ||
          element.field === 'Flagdetail'
        ) {
          fd[element.field] = 'D01'
        } else if (booltgl) {
          fd[element.field] = null
        } else if (element.cellEditor === 'numericEditor') {
          fd[element.field] = 0
        } else {
          fd[element.field] = null
        }
      })
      fd.id = this.$uuid.v4()
      fd.newdata = true
      this.formData.pengurus.splice(0, 0, fd)
    },
    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize()
      this.input.undoInput = undoSize
      this.input.undoInputdisable = undoSize < 1
      var redoSize = params.api.getCurrentRedoSize()
      this.input.redoInput = redoSize
      this.input.redoInputdisable = redoSize < 1
      if (this.mode === 'oncreate') {
        if (params.data.newdata) {
          var target = this.changedData.findIndex(
            (x) => x.id === params.data.id,
          )
          if (target < 0) {
            this.changedData.push(params.data)
          } else {
            this.changedData[target] = params.data
          }
          // console.log('this.changedData', this.changedData)
        } else {
          // eslint-disable-next-line no-redeclare
          var target = this.formData.pengurus.findIndex(
            (x) => x.id === params.data.id,
          )
          var backupTarget = this.backupRowData.findIndex(
            (x) => x.id === params.data.id,
          )
          // console.log('this.formData.pengurus[target] before:', this.formData.pengurus[target])
          this.formData.pengurus[target] = this.$g.clone(
            this.backupRowData[backupTarget],
          )
          // console.log('this.formData.pengurus[target] after:', this.formData.pengurus[target])
          // console.log('this.backupRowData[backupTarget]', this.backupRowData[backupTarget])
          // // this.componentKey += 1
          this.changedData = []
          this.input.undoInput = 0
          this.input.undoInputdisable = true

          this.input.redoInput = 0
          this.input.redoInputdisable = true
          lou.shownotif('Anda sedang dalam mode create')
        }
      } else {
        this.mode = 'onupdate'
        // eslint-disable-next-line no-redeclare
        var targetChanged = this.changedData.findIndex(
          (x) => x.id === params.data.id,
        )
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.changedData.push(params.data)
        } else {
          this.changedData[targetChanged][params.column.colId] =
            params.newValue
        }
        // this.componentKey += 1
      }
      // console.log('this.changedData', this.changedData)
      // console.log('this.mode', this.mode)
    },
    // async saveChanges() {
    //   var
    // },
    // onSelectionChanged() {
    //   var selectedRows = this.gridApi.getSelectedRows()
    //   this.selectedData = selectedRows
    //   // console.log('selectedRows', selectedRows[0].karat)
    //   // console.log('this.columnCurrency', this.columnCurrency)
    // },
    cancelaction() {
      // console.log('this.mode', this.mode)
      if (this.mode === 'oncreate') {
        var maped = this.formData.pengurus.filter(
          (x) => x.newdata === undefined,
        )
        // console.log('maped', maped)
        this.formData.pengurus = maped
      } else {
        // var changedRowNode = []
        this.formData.pengurus = this.$g.clone(this.backupRowData)
      }
      this.mode = 'netral'
      this.changedData = []
      this.input.undoInput = 0
      this.input.undoInputdisable = true

      this.input.redoInput = 0
      this.input.redoInputdisable = true
      this.refreshTable()
      // for (let i = 0; i < this.input.undoInput; i++) {
      //   this.gridApi.undoCellEditing()
      // }
    },
    refreshTable() {
      var params = {
        force: true,
        suppressFlash: true,
      }

      // this.gridOptions.api.refreshHeader()
      this.gridApi.refreshCells(params)
      // console.log('kerefresh kok')
    },
    nullFunction() {
      // console.log('%c! Nothing to see here! close debuging windows this instant or the app will automatically delete itself.%c', 'color: red; font-size:30px;', 'color: green; font-size:12px;')
      console.log(
        '%c! Hayooo... nyari apa disini... kalau error jangan di otak-atik sendiri, mending tanyakan team IT.%c',
        'color: red; font-size:30px;',
        'color: green; font-size:12px;',
      )
    },
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
      // const updateData = (data) => params.api.setRowData(data)
    },
    onFirstDataRendered() {
      this.autoSizeAll(false, { autosizecolumn: true })
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },
    back() {
      this.resetForm()
      this.$router.push('/cbsjombang/registerbadanusaha/data')
    },
    resetForm() {
      localStorage.removeItem('cbsjombangeditregisterbadanusahadata')
      this.formData = {
        kode: '',
        nama: '',
        sandibank: '',
        jenisbadanusaha: '',
        tglktp: moment(),
        ktp: '',
        tempatlahir: '',
        pekerjaan: '',
        instansi: '',
        nip: '',
        npwp: '',
        alamat: '',
        telepon: '',
        hp: '',
        fax: '',
        kodepos: '',
        kodya: '',
        kecamatan: '',
        kelurahan: '',
        rtrw: '',
        cabangentry: '',
        tglregister: moment(),
        pengurus: [],
      }
      // this.$router.push('/cbsjombang/registerbadanusaha/data')
    },
  },
}
</script>

<style lang="scss" scoped></style>
